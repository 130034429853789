
body {
  zoom: 0.8;
  background-color: #f5f5f5;
}


.container-menu .bg-black {
  background-color: #000;
}

/* eu */

.container-menu .navbar-menu{
  background:#212531 !important;

}

/* eu */

.container-menu #logo {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.container-menu .navbar-brand {
  padding: 14px 20px;
  font-size: 16px;
}

.container-menu .navbar-nav {
  width: 100%;
}

.container-menu .nav-item {
  padding: 6px 14px;
  text-align: center;
}

.container-menu .nav-link {
  background: none !important;
  padding-bottom: 10px;
  color: white; 
  border: none !important;
}


.container-menu .nav-link:hover {
  color: rgb(203, 203, 203);
}

/* .v-line {
  background-color:#cfcfcf;
  width: 1px;
  height: 30px;
} */

.container-menu .navbar-collapse.collapse.in {
  display: block !important;
}

@media (max-width: 576px) {
  .container-menu .nav-item {
      width: 100%;
      text-align: left;
  }

  .v-line {
      display: none;
  }
}


/* ------- */

#emex{
    background-image: url(./imgs/emex.png);
    height: 115vh;
    background-size: cover;
}

img.menu{
    display: block;
    width: 18vh;
    padding: 16px;
}


@media (max-width: 767px){
    #emex{
        background-image: url(./imgs/emex-mobile.png);
        height: 100vh;
        background-size: cover;
        background-position: center center;
    }
    
}

/* TROCA VENCIMENTO */

.rounded-lg {
  border-radius: 1rem;
}

.nav-pills .nav-link {
  color: #555;
}

.nav-pills .nav-link.wactive {
  color: #fff;
}

input[type=number]::-webkit-inner-spin-button { 
  -webkit-appearance: none;
  appearance: none;
}

input[type=number] { 
  -moz-appearance: textfield;
  appearance: textfield;

}

/* ANALISE DESCONTO */

#copiar{
  min-width: 100px;
}

/* MULTA RECISORIA */


.my-login-page { /*body*/
	background-color: #f7f9fb;
	font-size: 14px;
  width: 100%;
  height: 115vh;
}

.my-login-page .brand {
	width: 90px;
	height: 90px;
	overflow: hidden;
	border-radius: 50%;
	margin: 40px auto;
	box-shadow: 0 4px 8px rgba(0,0,0,.05);
	position: relative;
	z-index: 1;
}

.my-login-page .brand img {
	width: 100%;
}

.my-login-page .card-wrapper {
	width: 500px;
  margin-top: 60px;
}

.my-login-page .card {
	border-color: transparent;
	box-shadow: 0 4px 8px rgba(0,0,0,.05);
}

.my-login-page .card.fat {
	padding: 10px;
}

hr{
  border: 1px solid #cfcfcf;
}


.my-login-page .card .card-title {
	margin-bottom: 5px;
}

.my-login-page .form-control {
	border-width: 2.3px;
}

.my-login-page .form-check{
  display: flex;
  margin: 9px 0;
  align-items: center;
  justify-content: center;
}

.my-login-page .form-check-input{
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.my-login-page .form-group label {
	width: 100%;
}

.my-login-page .btn.btn-block {
	padding: 12px 10px;
}

.my-login-page .footer {
	margin: 40px 0;
	color: #888;
	text-align: center;
}

@media screen and (max-width: 425px) {
	/* .my-login-page .card-wrapper {
		width: 100%;
		margin: 0 auto;
	} */
}

@media screen and (max-width: 320px) {
	.my-login-page .card.fat {
		padding: 0;
	}

	.my-login-page .card.fat .card-body {
		padding: 15px;
	}
}

textarea.form-control{
    resize: none;
    
}

input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
}
input[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;

}

